<template>
  <div class="elite-tabs-wrapper-content">
    <h3 class="mb-4">{{ purchasesInvoice.code }}</h3>
    <div class="col-12">
      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
        <dd class="col-sm-8">
          {{ purchasesInvoice.created_at | moment("LLLL") }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">
          {{ $t("PURCHASES_INVOICES.EXPIRATION_TIME") }}
        </dt>
        <dd class="col-sm-8">
          {{ purchasesInvoice.expiration_time | moment("LLLL") }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.LOCATIONS") }}</dt>
        <dd class="col-sm-8">
          <locations :locations="purchasesInvoice.allowedLocations" />
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
        <dd class="col-sm-8">
          <organization :organization="purchasesInvoice.organization" />
        </dd>
      </dl>

      <dl class="row" v-if="purchasesInvoice.purchasesOrder">
        <dt class="col-sm-4">{{ $t("COMMON.PURCHASES_ORDER") }}</dt>
        <dd class="col-sm-8">
          <object-link :object="purchasesInvoice.purchasesOrder" />
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.ISSUER") }}</dt>
        <dd class="col-sm-8">
          <object-link :object="purchasesInvoice.issuer" />
        </dd>
      </dl>

      <dl class="row" v-if="purchasesInvoice.destinationWarehouse">
        <dt class="col-sm-4">{{ $t("COMMON.WAREHOUSE") }}</dt>
        <dd class="col-sm-8">
          <object-link :object="purchasesInvoice.destinationWarehouse" />
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.STATUS") }}</dt>
        <dd class="col-sm-8">
          <purchases-invoice-status-badge
            :purchasesInvoice="purchasesInvoice"
          />
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.TOTAL") }}</dt>
        <dd class="col-sm-8" v-if="purchasesInvoice.pricing">
          <span> {{ $formatCurrency(purchasesInvoice.pricing.total) }} </span>
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.TOTAL_PAIED") }}</dt>
        <dd class="col-sm-8">
          <span>
            {{
              $formatCurrency(
                purchasesInvoice.total_paied ? purchasesInvoice.total_paied : 0
              )
            }}
          </span>
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4" v-if="purchasesInvoice.excerpt">
          {{ $t("COMMON.NOTE") }}
        </dt>
        <dd class="col-sm-8" v-if="purchasesInvoice.excerpt">
          <div v-html="purchasesInvoice.excerpt"></div>
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
import PurchasesInvoiceStatusBadge from "./PurchasesInvoiceStatusBadge.vue";

export default {
  name: "purchases-invoice-view-global",

  components: {
    PurchasesInvoiceStatusBadge,
  },

  props: ["purchasesInvoice"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    purchasesInvoice(purchasesInvoice) {},
  },
};
</script>
