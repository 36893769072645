<template>
  <badge :type="getBadgeType()">{{
    statusesOptions[purchasesPayment.status]
  }}</badge>
</template>

<script>
import {
  paymentStatusesOption,
  PAYMENT_STATUS_CANCELED,
  PAYMENT_STATUS_COMPLETED,
  PAYMENT_STATUS_DRAFT,
} from "@/constants/payments";

export default {
  name: "purchasesPayment-status-badge",

  components: {},

  mixins: [],

  props: ["purchasesPayment"],

  data() {
    return {
      statusesOptions: paymentStatusesOption,
    };
  },

  computed: {},

  methods: {
    getBadgeType() {
      switch (this.purchasesPayment.status) {
        case PAYMENT_STATUS_DRAFT:
          return "primary";
        case PAYMENT_STATUS_COMPLETED:
          return "success";
        case PAYMENT_STATUS_CANCELED:
          return "danger";
        default:
          break;
      }
      return "default";
    },
  },

  mounted() {},

  watch: {},
};
</script>
