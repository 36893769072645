<template>
  <form
    class="add-form"
    ref="profile_form"
    @submit.prevent="handleSubmit"
    @keydown.enter.prevent="() => {}"
  >
    <div
      class="form-wrapper"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
    >
      <base-input
        :label="`${$t('COMMON.ORGANIZATION')} (*)`"
        :placeholder="$t('COMMON.ORGANIZATION')"
      >
        <organization-selector
          :allowNone="true"
          :organization="purchasesInvoice.organization.id"
          :filterable="true"
          :showAll="false"
          :disabled="!!purchasesInvoice.id"
          @organizationChanged="
            (organizationId) => {
              purchasesInvoice.organization.id = organizationId;
              purchasesInvoice.allowedLocations = [];
              purchasesInvoice.purchasesOrder.id = null;
              purchasesInvoice.issuer.id = null;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.organization" />
    </div>

    <div
      class="form-wrapper"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
    >
      <base-input
        :label="`${$t('COMMON.LOCATION')}`"
        :placeholder="$t('COMMON.LOCATION')"
      >
        <locations-selector
          :locations="purchasesInvoice.allowedLocations"
          :filterable="true"
          :showAll="false"
          :multiple="true"
          :organization="purchasesInvoice.organization.id"
          @locationsChanged="
            (locations) => {
              purchasesInvoice.allowedLocations = locations;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.location" />
    </div>

    <div
      class="form-wrapper"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_PURCHASES_ORDERS)"
    >
      <base-input
        :label="`${$t('COMMON.PURCHASES_ORDERS')}`"
        :placeholder="$t('COMMON.PURCHASES_ORDERS')"
      >
        <purchases-order-selector
          :allowNone="true"
          :purchasesOrder="
            purchasesInvoice.purchasesOrder
              ? purchasesInvoice.purchasesOrder.id
              : null
          "
          :filterable="true"
          :showAll="false"
          :disabled="!!purchasesInvoice.id"
          :filterOrganization="purchasesInvoice.organization.id"
          :filterStatus="ORDER_STATUS_VALIDATED"
          @purchasesOrderChanged="
            (purchasesOrderId, purchasesOrder) => {
              purchasesInvoice.purchasesOrder.id = purchasesOrderId;
              if (purchasesOrder) {
                purchasesInvoice.issuer.id = purchasesOrder.issuer.id;
                purchasesInvoice.destinationWarehouse.id =
                  purchasesOrder.destinationWarehouse?.id;
              }
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.purchasesOrder" />
    </div>

    <div class="form-wrapper">
      <base-input
        :label="`${$t('PURCHASES_INVOICES.EXPIRATION_TIME')} (*)`"
        :placeholder="$t('PURCHASES_INVOICES.EXPIRATION_TIME')"
      >
        <flat-picker
          :config="{
            allowInput: true,
            minDate: 'today',
            locale: $flatPickrLocale(),
          }"
          class="form-control datepicker"
          v-model="purchasesInvoice.expiration_time"
          @on-change="
            () => {
              onFormChanged();
            }
          "
        >
        </flat-picker>
      </base-input>
      <validation-error :errors="apiValidationErrors.expiration_time" />
    </div>

    <div class="form-wrapper">
      <base-input :label="`${$t(`PURCHASES_INVOICES.SELECT_SUPPLIERS`)} (*)`">
        <supplier-selector
          :allowNone="true"
          :supplier="purchasesInvoice.issuer.id"
          :filterable="true"
          :showAll="false"
          :filterOrganization="purchasesInvoice.organization.id"
          :disabled="!!purchasesInvoice.id"
          @supplierChanged="
            (supplierId) => {
              purchasesInvoice.issuer.id = supplierId;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.issuer" />
    </div>

    <div class="form-wrapper">
      <base-input :label="`${$t(`COMMON.DESTINATION_WAREHOUSE`)}`">
        <warehouse-selector
          :allowNone="true"
          :warehouse="
            purchasesInvoice.destinationWarehouse
              ? purchasesInvoice.destinationWarehouse.id
              : null
          "
          :filterable="true"
          :showAll="false"
          :filterOrganization="purchasesInvoice.organization.id"
          :disabled="!!purchasesInvoice.id"
          @warehouseChanged="
            (warehouseId) => {
              purchasesInvoice.destinationWarehouse.id = warehouseId;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.destinationWarehouse" />
    </div>

    <h2 class="col-12 mt-0 mb-0">{{ $t("COMMON.NOTE") }}</h2>

    <div class="form-wrapper full">
      <html-editor v-model="purchasesInvoice.excerpt" @change="onFormChanged()">
      </html-editor>
      <validation-error :errors="apiValidationErrors.excerpt" />
    </div>

    <div class="submit-wrapper">
      <base-button
        type="button"
        class="btn btn-sm elite-submit"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading"></i>
        {{
          purchasesInvoice.id
            ? $t("PURCHASES_INVOICES.EDIT_PURCHASES_INVOICE")
            : $t("PURCHASES_INVOICES.ADD_PURCHASES_INVOICE")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
import moment from "moment";
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { ORDER_STATUS_VALIDATED } from "@/constants/orders";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import PurchasesOrderSelector from "@/components/PurchasesOrderSelector.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";
import SupplierSelector from "@/components/SupplierSelector.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import WarehouseSelector from "@/components/WarehouseSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    OrganizationSelector,
    LocationsSelector,
    flatPicker,
    HtmlEditor,
    SupplierSelector,
    PurchasesOrderSelector,
    WarehouseSelector,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["purchasesInvoiceData", "formErrors", "loading"],

  data() {
    let purchasesInvoiceData = { ...this.purchasesInvoiceData };
    purchasesInvoiceData = this.$fillUserOrganizationData(purchasesInvoiceData);

    return {
      purchasesInvoice: purchasesInvoiceData,
      ORDER_STATUS_VALIDATED: ORDER_STATUS_VALIDATED,
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      let purchasesInvoiceData = cloneDeep(this.purchasesInvoice);
      purchasesInvoiceData.expiration_time = moment(
        purchasesInvoiceData.expiration_time
      ).toISOString();
      purchasesInvoiceData =
        this.$fillUserOrganizationData(purchasesInvoiceData);
      if (purchasesInvoiceData.purchasesOrder) {
        if (!purchasesInvoiceData.purchasesOrder.id) {
          delete purchasesInvoiceData.purchasesOrder;
        }
      }
      if (purchasesInvoiceData.destinationWarehouse) {
        if (!purchasesInvoiceData.destinationWarehouse.id) {
          delete purchasesInvoiceData.destinationWarehouse;
        }
      }
      this.$emit("purchasesInvoiceSubmitted", purchasesInvoiceData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    purchasesInvoiceData(purchasesInvoiceData) {
      if (purchasesInvoiceData) {
        this.purchasesInvoice = {
          ...this.purchasesInvoice,
          ...cloneDeep(purchasesInvoiceData),
        };
        if (!this.purchasesInvoice.organization) {
          this.purchasesInvoice.organization = {
            type: "organizations",
            id: null,
          };
        }
      }
    },
  },
};
</script>
