<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-0">
              {{ $t("PURCHASES_INVOICES.PURCHASES_INVOICES_LIST") }}
            </h3>
          </div>
          <div class="col-6 text-right">
            <base-button
              class="elite-button add"
              icon
              size="sm"
              @click="openPurchasesInvoiceCreateModal"
              v-if="
                $currentUserCan($permissions.PERM_CREATE_PURCHASES_INVOICES)
              "
            >
              <span class="btn-inner--icon">
                <i class="far fa-plus-circle"></i>
              </span>
              <span class="btn-inner--text">{{
                $t("PURCHASES_INVOICES.ADD_PURCHASES_INVOICE")
              }}</span>
            </base-button>
            <notification-subscription
              v-if="$currentUserCan($permissions.PERM_VIEW_PURCHASES_INVOICES)"
              :objectType="'PurchasesInvoices'"
              :events="{
                CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
                UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
              }"
            />
          </div>
        </div>
      </div>

      <purchases-invoice-list-table
        @onViewPurchasesInvoice="openPurchasesInvoiceViewModal"
        @onEditPurchasesInvoice="openPurchasesInvoiceEditModal"
        @onDeletePurchasesInvoice="deletePurchasesInvoice"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewPurchasesInvoiceModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewPurchasesInvoiceModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'PURCHASES_INVOICE_VIEW'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("PURCHASES_INVOICES.VIEW_PURCHASES_INVOICE") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <base-button
                type="primary"
                icon
                size="sm"
                @click="validatePurchasesInvoice(openPurchasesInvoice)"
                v-if="
                  openPurchasesInvoiceLoaded
                    ? openPurchasesInvoice.items.length > 0 &&
                      openPurchasesInvoice.status === INVOICE_STATUS_DRAFT
                    : false
                "
                :title="$t('PURCHASES_INVOICES.VALIDATE')"
              >
                <span class="btn-inner--icon">
                  <i class="ni ni-check-bold"></i>
                </span>
              </base-button>

              <base-button
                v-if="
                  openPurchasesInvoiceLoaded
                    ? openPurchasesInvoice.status === INVOICE_STATUS_VALIDATED
                    : false
                "
                type="primary"
                icon
                size="sm"
                @click="addPurchasesPayment(openPurchasesInvoice)"
                :title="$t('PURCHASES_INVOICES.ADD_PAYMENT')"
              >
                <span class="btn-inner--icon">
                  <i class="ni ni-money-coins"></i>
                </span>
              </base-button>

              <base-button
                type="primary"
                icon
                size="sm"
                @click="viewPurchasesOrder(openPurchasesInvoice)"
                v-if="
                  openPurchasesInvoiceLoaded
                    ? openPurchasesInvoice.purchasesOrder
                    : false
                "
                :title="$t('PURCHASES_INVOICES.VIEW_ORDER')"
              >
                <span class="btn-inner--icon">
                  <i class="ni ni-bag-17"></i>
                </span>
              </base-button>

              <base-button
                type="danger"
                icon
                size="sm"
                @click="cancelPurchasesInvoice(openPurchasesInvoice)"
                v-if="canCancelPurchasesInvoice"
                :title="$t('PURCHASES_INVOICES.CANCEL')"
              >
                <span class="btn-inner--icon">
                  <i class="ni ni-fat-remove"></i>
                </span>
              </base-button>

              <notification-subscription
                v-if="openPurchasesInvoice"
                :objectType="'PurchasesInvoices'"
                :objectId="openPurchasesInvoice.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button
                  class="edit"
                  @click="openPurchasesInvoiceEditModal(openPurchasesInvoice)"
                  v-if="
                    openPurchasesInvoice.status === INVOICE_STATUS_DRAFT &&
                    $currentUserCan($permissions.PERM_EDIT_PURCHASES_INVOICES)
                  "
                >
                  <i class="fal fa-edit"></i>
                  <span>{{ $t("COMMON.EDIT") }}</span>
                </button>
                <button
                  class="edit"
                  @click="deletePurchasesInvoice(openPurchasesInvoice)"
                  v-if="
                    openPurchasesInvoice.status === INVOICE_STATUS_DRAFT &&
                    $currentUserCan($permissions.PERM_DELETE_PURCHASES_INVOICES)
                  "
                >
                  <i class="fal fa-trash-alt"></i>
                  <span>{{ $t("COMMON.DELETE") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closePurchasesInvoiceModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <view-purchases-invoice-page
              v-if="openPurchasesInvoice"
              :purchasesInvoiceId="openPurchasesInvoice.id"
              @purchasesInvoiceLoaded="
                (purchasesInvoice) => {
                  openPurchasesInvoice = purchasesInvoice;
                  openPurchasesInvoiceLoaded = true;
                }
              "
            />
          </div>
        </div>
      </div>

      <div
        v-if="isEditPurchasesInvoiceModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditPurchasesInvoiceModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'PURCHASES_INVOICE'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("PURCHASES_INVOICES.EDIT_PURCHASES_INVOICE") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button
                  class="view"
                  @click="openPurchasesInvoiceViewModal(openPurchasesInvoice)"
                >
                  <i class="fal fa-eye"></i>
                  <span>{{ $t("COMMON.VIEW") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closePurchasesInvoiceModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-purchases-invoice-page
              v-if="openPurchasesInvoice"
              :purchasesInvoiceId="openPurchasesInvoice.id"
              @onViewPurchasesInvoice="openPurchasesInvoiceViewModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddPurchasesInvoiceModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddPurchasesInvoiceModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'PURCHASES_INVOICE'"
        >
          <div class="resizable-wrapper-content-header">
            <h1>
              {{ $t("PURCHASES_INVOICES.ADD_PURCHASES_INVOICE") }}
            </h1>
            <button class="close" @click="closePurchasesInvoiceModal">
              <i class="fal fa-times"></i>
            </button>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-purchases-invoice-page
              @onViewPurchasesInvoice="openPurchasesInvoiceViewModal"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  INVOICE_STATUS_CANCELED,
  INVOICE_STATUS_DRAFT,
  INVOICE_STATUS_VALIDATED,
} from "@/constants/invoices";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import PurchasesInvoiceListTable from "./partials/PurchasesInvoiceListTable.vue";
import EditPurchasesInvoicePage from "./components/EditPurchasesInvoiceComponent.vue";
import AddPurchasesInvoicePage from "./components/AddPurchasesInvoiceComponent.vue";
import ViewPurchasesInvoicePage from "./components/ViewPurchasesInvoiceComponent.vue";

export default {
  layout: "DashboardLayout",

  components: {
    PurchasesInvoiceListTable,
    NotificationSubscription,
    EditPurchasesInvoicePage,
    AddPurchasesInvoicePage,
    ViewPurchasesInvoicePage,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {
    canCancelPurchasesInvoice() {
      if (
        this.openPurchasesInvoice.status === INVOICE_STATUS_CANCELED ||
        this.openPurchasesInvoice.status === INVOICE_STATUS_DRAFT
      ) {
        return false;
      }
      if (this.openPurchasesInvoice.status === INVOICE_STATUS_VALIDATED) {
        if (this.openPurchasesInvoice.total_paied != 0) {
          return false;
        }
        return true;
      }
      return false;
    },
  },

  watch: {},

  data() {
    const locationId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewPurchasesInvoiceModalOpened = false;
    let isEditPurchasesInvoiceModalOpened = false;
    let isAddPurchasesInvoiceModalOpened = false;
    let openPurchasesInvoice = null;
    if (locationId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewPurchasesInvoiceModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditPurchasesInvoiceModalOpened = true;
      }
      openPurchasesInvoice = { id: locationId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddPurchasesInvoiceModalOpened = true;
    }
    return {
      isViewPurchasesInvoiceModalOpened: isViewPurchasesInvoiceModalOpened,
      isEditPurchasesInvoiceModalOpened: isEditPurchasesInvoiceModalOpened,
      isAddPurchasesInvoiceModalOpened: isAddPurchasesInvoiceModalOpened,
      openPurchasesInvoice: openPurchasesInvoice,
      openPurchasesInvoiceLoaded: false,
      renderKey: 1,
      INVOICE_STATUS_DRAFT: INVOICE_STATUS_DRAFT,
      INVOICE_STATUS_CANCELED: INVOICE_STATUS_CANCELED,
      INVOICE_STATUS_VALIDATED: INVOICE_STATUS_VALIDATED,
    };
  },

  methods: {
    openPurchasesInvoiceCreateModal() {
      this.closePurchasesInvoiceModal();
      this.isAddPurchasesInvoiceModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List PurchasesInvoices",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },

    openPurchasesInvoiceViewModal(purchasesInvoice, reRender = false) {
      this.closePurchasesInvoiceModal();
      this.openPurchasesInvoice = purchasesInvoice;
      this.isViewPurchasesInvoiceModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List PurchasesInvoices",
          query: {
            id: this.openPurchasesInvoice.id,
            action: QUERY_ACTIONS_VIEW,
          },
        }).href
      );
    },

    openPurchasesInvoiceEditModal(purchasesInvoice) {
      this.closePurchasesInvoiceModal();
      this.openPurchasesInvoice = purchasesInvoice;
      this.isEditPurchasesInvoiceModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List PurchasesInvoices",
          query: {
            id: this.openPurchasesInvoice.id,
            action: QUERY_ACTIONS_EDIT,
          },
        }).href
      );
    },

    closePurchasesInvoiceModal() {
      this.isAddPurchasesInvoiceModalOpened = false;
      this.isViewPurchasesInvoiceModalOpened = false;
      this.isEditPurchasesInvoiceModalOpened = false;
      this.openPurchasesInvoiceLoaded = false;
      this.openPurchasesInvoice = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List PurchasesInvoices",
          query: {},
        }).href
      );
    },

    async viewPurchasesOrder(purchasesInvoice) {
      this.$router.push(this.$objectViewRoute(purchasesInvoice.purchasesOrder));
    },

    async validatePurchasesInvoice(purchasesInvoice) {
      try {
        await this.$store.dispatch(
          "purchasesInvoices/validate",
          purchasesInvoice.id
        );
        this.openPurchasesInvoice =
          this.$store.getters["purchasesInvoices/purchasesInvoice"];
        this.renderKey++;
        this.$notify({
          type: "success",
          message: this.$t("PURCHASES_INVOICES.PURCHASES_INVOICE_VALIDATED"),
        });
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async cancelPurchasesInvoice(purchasesInvoice) {
      const confirmation = await swal.fire({
        title: this.$t("PURCHASES_INVOICES.CANCEL_PURCHASES_INVOICE_QUESTION"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      if (!confirmation.value) {
        return;
      }

      try {
        await this.$store.dispatch(
          "purchasesInvoices/cancel",
          purchasesInvoice.id
        );
        this.openPurchasesInvoice =
          this.$store.getters["purchasesInvoices/purchasesInvoice"];
        this.renderKey++;
        this.$notify({
          type: "success",
          message: this.$t("PURCHASES_INVOICES.PURCHASES_INVOICE_CANCELED"),
        });
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async addPurchasesPayment(purchasesInvoice) {
      this.$router.push({
        name: "Add PurchasesPayment",
        query: {
          invoice_id: purchasesInvoice.id,
          amount: purchasesInvoice.total_remaining_payment,
        },
      });
    },

    async deletePurchasesInvoice(purchasesInvoice) {
      const confirmation = await swal.fire({
        title: this.$t("PURCHASES_INVOICES.DELETE_THIS_PURCHASES_INVOICE"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch(
            "purchasesInvoices/destroy",
            purchasesInvoice.id
          );
          this.renderKey++;
          this.closePurchasesInvoiceModal();
          this.$notify({
            type: "success",
            message: this.$t("PURCHASES_INVOICES.PURCHASES_INVOICE_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
