<template>
  <badge :type="getBadgeType()">
    {{ statusesOptions[purchasesInvoice.status] }}
  </badge>
</template>

<script>
import {
  invoiceStatusesOption,
  INVOICE_STATUS_DRAFT,
  INVOICE_STATUS_SENT,
  INVOICE_STATUS_CANCELED,
  INVOICE_STATUS_DELIVERED,
  INVOICE_STATUS_PAID,
} from "@/constants/invoices";

export default {
  name: "purchases-invoice-status-badge",

  components: {},

  mixins: [],

  props: ["purchasesInvoice"],

  data() {
    return {
      statusesOptions: invoiceStatusesOption,
    };
  },

  computed: {},

  methods: {
    getBadgeType() {
      switch (this.purchasesInvoice.status) {
        case INVOICE_STATUS_DRAFT:
          return "primary";
        case INVOICE_STATUS_SENT:
          return "default";
        case INVOICE_STATUS_CANCELED:
          return "danger";
        case INVOICE_STATUS_DELIVERED:
          return "success";
        case INVOICE_STATUS_PAID:
          return "success";
        default:
          break;
      }
      return "default";
    },
  },

  mounted() {},

  watch: {},
};
</script>
