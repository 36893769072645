var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{ref:"profile_form",staticClass:"add-form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return (() => {}).apply(null, arguments)}}},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS))?_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ORGANIZATION')} (*)`,"placeholder":_vm.$t('COMMON.ORGANIZATION')}},[_c('organization-selector',{attrs:{"allowNone":true,"organization":_vm.purchasesInvoice.organization.id,"filterable":true,"showAll":false,"disabled":!!_vm.purchasesInvoice.id},on:{"organizationChanged":(organizationId) => {
            _vm.purchasesInvoice.organization.id = organizationId;
            _vm.purchasesInvoice.allowedLocations = [];
            _vm.purchasesInvoice.purchasesOrder.id = null;
            _vm.purchasesInvoice.issuer.id = null;
            _vm.onFormChanged();
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}})],1):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOCATIONS))?_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.LOCATION')}`,"placeholder":_vm.$t('COMMON.LOCATION')}},[_c('locations-selector',{attrs:{"locations":_vm.purchasesInvoice.allowedLocations,"filterable":true,"showAll":false,"multiple":true,"organization":_vm.purchasesInvoice.organization.id},on:{"locationsChanged":(locations) => {
            _vm.purchasesInvoice.allowedLocations = locations;
            _vm.onFormChanged();
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.location}})],1):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_PURCHASES_ORDERS))?_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.PURCHASES_ORDERS')}`,"placeholder":_vm.$t('COMMON.PURCHASES_ORDERS')}},[_c('purchases-order-selector',{attrs:{"allowNone":true,"purchasesOrder":_vm.purchasesInvoice.purchasesOrder
            ? _vm.purchasesInvoice.purchasesOrder.id
            : null,"filterable":true,"showAll":false,"disabled":!!_vm.purchasesInvoice.id,"filterOrganization":_vm.purchasesInvoice.organization.id,"filterStatus":_vm.ORDER_STATUS_VALIDATED},on:{"purchasesOrderChanged":(purchasesOrderId, purchasesOrder) => {
            _vm.purchasesInvoice.purchasesOrder.id = purchasesOrderId;
            if (purchasesOrder) {
              _vm.purchasesInvoice.issuer.id = purchasesOrder.issuer.id;
              _vm.purchasesInvoice.destinationWarehouse.id =
                purchasesOrder.destinationWarehouse?.id;
            }
            _vm.onFormChanged();
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.purchasesOrder}})],1):_vm._e(),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('PURCHASES_INVOICES.EXPIRATION_TIME')} (*)`,"placeholder":_vm.$t('PURCHASES_INVOICES.EXPIRATION_TIME')}},[_c('flat-picker',{staticClass:"form-control datepicker",attrs:{"config":{
          allowInput: true,
          minDate: 'today',
          locale: _vm.$flatPickrLocale(),
        }},on:{"on-change":() => {
            _vm.onFormChanged();
          }},model:{value:(_vm.purchasesInvoice.expiration_time),callback:function ($$v) {_vm.$set(_vm.purchasesInvoice, "expiration_time", $$v)},expression:"purchasesInvoice.expiration_time"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.expiration_time}})],1),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t(`PURCHASES_INVOICES.SELECT_SUPPLIERS`)} (*)`}},[_c('supplier-selector',{attrs:{"allowNone":true,"supplier":_vm.purchasesInvoice.issuer.id,"filterable":true,"showAll":false,"filterOrganization":_vm.purchasesInvoice.organization.id,"disabled":!!_vm.purchasesInvoice.id},on:{"supplierChanged":(supplierId) => {
            _vm.purchasesInvoice.issuer.id = supplierId;
            _vm.onFormChanged();
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.issuer}})],1),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t(`COMMON.DESTINATION_WAREHOUSE`)}`}},[_c('warehouse-selector',{attrs:{"allowNone":true,"warehouse":_vm.purchasesInvoice.destinationWarehouse
            ? _vm.purchasesInvoice.destinationWarehouse.id
            : null,"filterable":true,"showAll":false,"filterOrganization":_vm.purchasesInvoice.organization.id,"disabled":!!_vm.purchasesInvoice.id},on:{"warehouseChanged":(warehouseId) => {
            _vm.purchasesInvoice.destinationWarehouse.id = warehouseId;
            _vm.onFormChanged();
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.destinationWarehouse}})],1),_c('h2',{staticClass:"col-12 mt-0 mb-0"},[_vm._v(_vm._s(_vm.$t("COMMON.NOTE")))]),_c('div',{staticClass:"form-wrapper full"},[_c('html-editor',{on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.purchasesInvoice.excerpt),callback:function ($$v) {_vm.$set(_vm.purchasesInvoice, "excerpt", $$v)},expression:"purchasesInvoice.excerpt"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.excerpt}})],1),_c('div',{staticClass:"submit-wrapper"},[_c('base-button',{staticClass:"btn btn-sm elite-submit",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.purchasesInvoice.id ? _vm.$t("PURCHASES_INVOICES.EDIT_PURCHASES_INVOICE") : _vm.$t("PURCHASES_INVOICES.ADD_PURCHASES_INVOICE"))+" ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }