<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!purchasesInvoice">
      <span class="loader"></span>
    </span>
    <div v-if="purchasesInvoice" class="elite-tabs-wrapper">
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav elite-tabs"
        value="details"
      >
        <tab-pane title="global" id="1" :active="false">
          <span slot="title">
            <i class="ni ni-cloud-upload-96"></i>
            {{ $t("COMMON.GLOBAL") }}
          </span>
          <purchases-invoice-view-global :purchasesInvoice="purchasesInvoice" />
        </tab-pane>

        <tab-pane title="details" id="2" :active="false">
          <span slot="title">
            <i class="ni ni-bullet-list-67"></i>
            {{ $t("COMMON.DETAILS") }}
          </span>
          <purchases-invoice-view-details
            :purchasesInvoice="purchasesInvoice"
            @purchasesInvoiceItemsUpdated="get"
          />
        </tab-pane>

        <tab-pane
          title="payments"
          id="3"
          :active="false"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_PURCHASES_PAYMENTS)"
        >
          <span slot="title">
            <i class="ni ni-money-coins"></i>
            {{ $t("COMMON.PAYMENTS") }}
          </span>
          <purchases-invoice-view-payments
            :purchasesInvoice="purchasesInvoice"
          />
        </tab-pane>

        <tab-pane
          title="files"
          id="998"
          v-if="
            $currentUserCan($permissions.PERM_VIEW_ANY_FILES) &&
            purchasesInvoice.organization
          "
        >
          <span slot="title">
            <i class="ni ni-folder-17"></i>
            {{ $t("COMMON.FILES") }}
          </span>
          <div class="elite-tabs-wrapper-content">
            <list-file-component :object="purchasesInvoice" />
          </div>
        </tab-pane>

        <tab-pane
          title="logs"
          id="999"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
        >
          <span slot="title">
            <i class="fa fa-file"></i>
            {{ $t("COMMON.LOGS") }}
          </span>
          <purchases-invoice-view-logs :purchasesInvoice="purchasesInvoice" />
        </tab-pane>
      </tabs>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import swal from "sweetalert2";
import {
  INVOICE_STATUS_CANCELED,
  INVOICE_STATUS_DRAFT,
  INVOICE_STATUS_VALIDATED,
} from "@/constants/invoices";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { Tabs, TabPane } from "@/components";
import ListFileComponent from "@/components/File/ListFileComponent.vue";
import PurchasesInvoiceViewGlobal from "../partials/PurchasesInvoiceViewGlobal.vue";
import PurchasesInvoiceViewDetails from "../partials/PurchasesInvoiceViewDetails.vue";
import PurchasesInvoiceViewLogs from "../partials/PurchasesInvoiceViewLogs.vue";
import PurchasesInvoiceViewPayments from "../partials/PurchasesInvoiceViewPayments.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    PurchasesInvoiceViewGlobal,
    PurchasesInvoiceViewDetails,
    PurchasesInvoiceViewLogs,
    ListFileComponent,
    PurchasesInvoiceViewPayments,
  },

  mixins: [requestErrorMixin],

  props: {
    purchasesInvoiceId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      purchasesInvoice: null,
      INVOICE_STATUS_DRAFT: INVOICE_STATUS_DRAFT,
      INVOICE_STATUS_CANCELED: INVOICE_STATUS_CANCELED,
      INVOICE_STATUS_VALIDATED: INVOICE_STATUS_VALIDATED,
    };
  },

  computed: {
    canCancelPurchasesInvoice() {
      if (
        this.purchasesInvoice.status === INVOICE_STATUS_CANCELED ||
        this.purchasesInvoice.status === INVOICE_STATUS_DRAFT
      ) {
        return false;
      }
      if (this.purchasesInvoice.status === INVOICE_STATUS_VALIDATED) {
        return true;
      }
      return false;
    },
  },

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch(
          "purchasesInvoices/get",
          this.purchasesInvoiceId
        );
        this.purchasesInvoice =
          this.$store.getters["purchasesInvoices/purchasesInvoice"];
        this.$emit("purchasesInvoiceLoaded", this.purchasesInvoice);
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
