import defaultTaxes from "@/constants/defaultTaxes";

export default {
  type: "purchases-invoice-items",
  code: null,
  excerpt: "",
  unit_price: null,
  quantity: 1,
  discount: 0,
  relationshipNames: ["purchasesInvoice", "purchasesInvoiceable"],
  purchasesInvoice: {
    type: "purchases-invoices",
    id: null,
  },
  purchasesInvoiceable: {
    type: "products",
    id: null,
  },
  pricing: {},
  taxes: defaultTaxes,
};
